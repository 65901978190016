import React, { Component } from 'react'

import './app.css'

class App extends Component {

  buttonClicked( color ) {
    document.body.style.backgroundColor = color;
  }

  renderButtons() {
      const data = [{color: '#cb132d', name: 'red'},
          {name: 'purple', color: '#bf00ff'},
          {name: 'blue', color:'#2C75FF'},
          {name:'relax', color: '#eb5c34'},
          {name: 'bright', color: 'white'}];
      return (<span> {data.map( (datum, index ) => (
          <button key={index} className="Color-button" onClick={ () => this.buttonClicked( datum.color ) }>{datum.name}</button>
      ))} </span> )
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
              {this.renderButtons()}
        </header>
      </div>
    )
  }
}

export default App
