

  
// Template Map
export default {
  '/usr/local/lib/node_modules/react-static/lib/browser/components/Default404': require('/usr/local/lib/node_modules/react-static/lib/browser/components/Default404').default
}

export const notFoundTemplate = '/usr/local/lib/node_modules/react-static/lib/browser/components/Default404'

