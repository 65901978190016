// Imports


// Plugins
const plugins = [{
        location: "/Users/josh/Desktop/code/lightbulb/lightbulb",
        plugins: [],
        hooks: {}
      }]

// Export em!
export default plugins